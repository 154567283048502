/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const approveUser = /* GraphQL */ `
  mutation ApproveUser($userId: String, $userEmail: String) {
    approveUser(userId: $userId, userEmail: $userEmail)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($userId: String) {
    disableUser(userId: $userId)
  }
`;
export const alert = /* GraphQL */ `
  mutation Alert($sensor: String, $message: String, $connectivity: Boolean) {
    alert(sensor: $sensor, message: $message, connectivity: $connectivity)
  }
`;
export const createTTNSensor = /* GraphQL */ `
  mutation CreateTTNSensor(
    $devEui: String
    $joinEui: String
    $appKey: String
    $model: String
  ) {
    createTTNSensor(
      devEui: $devEui
      joinEui: $joinEui
      appKey: $appKey
      model: $model
    )
  }
`;
export const acknowledge = /* GraphQL */ `
  mutation Acknowledge(
    $alarmId: String
    $eventId: String
    $responseBy: String
    $sensorId: String
    $secondAlarm: String
  ) {
    acknowledge(
      alarmId: $alarmId
      eventId: $eventId
      responseBy: $responseBy
      sensorId: $sensorId
      secondAlarm: $secondAlarm
    )
  }
`;
export const moveStock = /* GraphQL */ `
  mutation MoveStock(
    $devEui: String
    $name: String
    $email: String
    $sensorLocationId: String
    $profile: String
    $sensorDeviceId: String
    $sensorUserId: String
  ) {
    moveStock(
      devEui: $devEui
      name: $name
      email: $email
      sensorLocationId: $sensorLocationId
      profile: $profile
      sensorDeviceId: $sensorDeviceId
      sensorUserId: $sensorUserId
    )
  }
`;
export const adminUpdates = /* GraphQL */ `
  mutation AdminUpdates($func: String, $val: String) {
    adminUpdates(func: $func, val: $val)
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore($input: DeleteStoreInput!) {
    deleteStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStock = /* GraphQL */ `
  mutation CreateStock($input: CreateStockInput!) {
    createStock(input: $input) {
      devEui
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      brand
      model
      firmware
      sn
      daddr
      profile
      joinEui
      appKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStock = /* GraphQL */ `
  mutation UpdateStock($input: UpdateStockInput!) {
    updateStock(input: $input) {
      devEui
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      brand
      model
      firmware
      sn
      daddr
      profile
      joinEui
      appKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStock = /* GraphQL */ `
  mutation DeleteStock($input: DeleteStockInput!) {
    deleteStock(input: $input) {
      devEui
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      brand
      model
      firmware
      sn
      daddr
      profile
      joinEui
      appKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGateway = /* GraphQL */ `
  mutation CreateGateway($input: CreateGatewayInput!) {
    createGateway(input: $input) {
      devEui
      connected
      lastCheckIn
      lastPacket
      firstConnect
      brand
      model
      sn
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gatewayOwnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGateway = /* GraphQL */ `
  mutation UpdateGateway($input: UpdateGatewayInput!) {
    updateGateway(input: $input) {
      devEui
      connected
      lastCheckIn
      lastPacket
      firstConnect
      brand
      model
      sn
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gatewayOwnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGateway = /* GraphQL */ `
  mutation DeleteGateway($input: DeleteGatewayInput!) {
    deleteGateway(input: $input) {
      devEui
      connected
      lastCheckIn
      lastPacket
      firstConnect
      brand
      model
      sn
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gatewayOwnerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      gateways {
        items {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      gateways {
        items {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        stores {
          items {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      store {
        id
        name
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locations {
        items {
          id
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subs {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      status
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      fuelCells {
        items {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensorActive
      payment
      business
      degreePref
      resolution
      gateways {
        items {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDistributor = /* GraphQL */ `
  mutation CreateDistributor($input: CreateDistributorInput!) {
    createDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDistributor = /* GraphQL */ `
  mutation UpdateDistributor($input: UpdateDistributorInput!) {
    updateDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDistributor = /* GraphQL */ `
  mutation DeleteDistributor($input: DeleteDistributorInput!) {
    deleteDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      stores {
        items {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReading = /* GraphQL */ `
  mutation CreateReading($input: CreateReadingInput!) {
    createReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      session {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        product {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        high
        low
        min
        max
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateReading = /* GraphQL */ `
  mutation UpdateReading($input: UpdateReadingInput!) {
    updateReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      session {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        product {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        high
        low
        min
        max
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteReading = /* GraphQL */ `
  mutation DeleteReading($input: DeleteReadingInput!) {
    deleteReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      error
      readingSensorId
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      minFull
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingTreatmentId
      session {
        id
        createdAt
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        product {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        high
        low
        min
        max
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createFuelCell = /* GraphQL */ `
  mutation CreateFuelCell($input: CreateFuelCellInput!) {
    createFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFuelCell = /* GraphQL */ `
  mutation UpdateFuelCell($input: UpdateFuelCellInput!) {
    updateFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFuelCell = /* GraphQL */ `
  mutation DeleteFuelCell($input: DeleteFuelCellInput!) {
    deleteFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          murphyTemps {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tunnel
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment($input: UpdateTreatmentInput!) {
    updateTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTreatment = /* GraphQL */ `
  mutation DeleteTreatment($input: DeleteTreatmentInput!) {
    deleteTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      murphyTemps {
        items {
          id
          temp
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTemp = /* GraphQL */ `
  mutation CreateTemp($input: CreateTempInput!) {
    createTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTemp = /* GraphQL */ `
  mutation UpdateTemp($input: UpdateTempInput!) {
    updateTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTemp = /* GraphQL */ `
  mutation DeleteTemp($input: DeleteTempInput!) {
    deleteTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        murphyTemps {
          items {
            id
            temp
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor($input: CreateSensorInput!) {
    createSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      rssi
      updateInterval
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gateway {
        devEui
        connected
        lastCheckIn
        lastPacket
        firstConnect
        brand
        model
        sn
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gatewayOwnerId
        createdAt
        updatedAt
        __typename
      }
      sensorGatewayId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor($input: UpdateSensorInput!) {
    updateSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      rssi
      updateInterval
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gateway {
        devEui
        connected
        lastCheckIn
        lastPacket
        firstConnect
        brand
        model
        sn
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gatewayOwnerId
        createdAt
        updatedAt
        __typename
      }
      sensorGatewayId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor($input: DeleteSensorInput!) {
    deleteSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      currentTempFull
      certified
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          items {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        tunnel
        createdAt
        updatedAt
        __typename
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sensorDeviceId
      sensorUserId
      rssi
      updateInterval
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      active
      alert
      alarm
      high
      low
      highC
      lowC
      email
      phone
      time
      raw
      duration
      monitor
      battery
      humidity
      leak
      subs {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          log {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          notificationSensorId
          messages {
            nextToken
            __typename
          }
          events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reports {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          period
          reportSensorId
          periodStartTimestamp
          highTemps
          lowTemps
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      reportingEnabled
      andrewTest
      validated
      timezone
      degreePref
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          name
          high
          low
          degreePref
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sessions {
            nextToken
            __typename
          }
          type
          min
          max
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gateway {
        devEui
        connected
        lastCheckIn
        lastPacket
        firstConnect
        brand
        model
        sn
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gatewayOwnerId
        createdAt
        updatedAt
        __typename
      }
      sensorGatewayId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        valueC
        predicate
        duration
        active
        ack
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        alarmSensorId
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        activeDays
        createdAt
        updatedAt
        __typename
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      resolved
      status
      result
      notify
      notifications {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notes
      updatedAt
      __typename
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog($input: CreateLogInput!) {
    createLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog($input: UpdateLogInput!) {
    updateLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog($input: DeleteLogInput!) {
    deleteLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      logSensorId
      type
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      trigger
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAlarm = /* GraphQL */ `
  mutation CreateAlarm($input: CreateAlarmInput!) {
    createAlarm(input: $input) {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      activeDays
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAlarm = /* GraphQL */ `
  mutation UpdateAlarm($input: UpdateAlarmInput!) {
    updateAlarm(input: $input) {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      activeDays
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAlarm = /* GraphQL */ `
  mutation DeleteAlarm($input: DeleteAlarmInput!) {
    deleteAlarm(input: $input) {
      id
      input
      value
      valueC
      predicate
      duration
      active
      ack
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      alarmSensorId
      events {
        items {
          id
          createdAt
          alarm {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          logs {
            nextToken
            __typename
          }
          messages {
            nextToken
            __typename
          }
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          resolved
          status
          result
          notify
          notifications {
            nextToken
            __typename
          }
          notes
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      activeDays
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCanBus = /* GraphQL */ `
  mutation CreateCanBus($input: CreateCanBusInput!) {
    createCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCanBus = /* GraphQL */ `
  mutation UpdateCanBus($input: UpdateCanBusInput!) {
    updateCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCanBus = /* GraphQL */ `
  mutation DeleteCanBus($input: DeleteCanBusInput!) {
    deleteCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      log {
        items {
          id
          createdAt
          alarm
          alerts
          logSensorId
          type
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          trigger
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      notificationSensorId
      messages {
        items {
          id
          alert {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          responseBy
          userResponse
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          notification {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventNotification = /* GraphQL */ `
  mutation CreateEventNotification($input: CreateEventNotificationInput!) {
    createEventNotification(input: $input) {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventNotification = /* GraphQL */ `
  mutation UpdateEventNotification($input: UpdateEventNotificationInput!) {
    updateEventNotification(input: $input) {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventNotification = /* GraphQL */ `
  mutation DeleteEventNotification($input: DeleteEventNotificationInput!) {
    deleteEventNotification(input: $input) {
      id
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      notification {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdminLocation = /* GraphQL */ `
  mutation CreateAdminLocation($input: CreateAdminLocationInput!) {
    createAdminLocation(input: $input) {
      id
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdminLocation = /* GraphQL */ `
  mutation UpdateAdminLocation($input: UpdateAdminLocationInput!) {
    updateAdminLocation(input: $input) {
      id
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdminLocation = /* GraphQL */ `
  mutation DeleteAdminLocation($input: DeleteAdminLocationInput!) {
    deleteAdminLocation(input: $input) {
      id
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      alert {
        id
        type
        info
        active
        default
        pause
        nickname
        log {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        notificationSensorId
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        createdAt
        alarm {
          id
          input
          value
          valueC
          predicate
          duration
          active
          ack
          pause
          default
          start
          stop
          type
          timezone
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          alarmSensorId
          events {
            nextToken
            __typename
          }
          activeDays
          createdAt
          updatedAt
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        messages {
          items {
            id
            responseBy
            userResponse
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        resolved
        status
        result
        notify
        notifications {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        updatedAt
        __typename
      }
      responseBy
      userResponse
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCode = /* GraphQL */ `
  mutation CreateCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCode = /* GraphQL */ `
  mutation UpdateCode($input: UpdateCodeInput!) {
    updateCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCode = /* GraphQL */ `
  mutation DeleteCode($input: DeleteCodeInput!) {
    deleteCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          tag {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensorTag = /* GraphQL */ `
  mutation CreateSensorTag($input: CreateSensorTagInput!) {
    createSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensorTag = /* GraphQL */ `
  mutation UpdateSensorTag($input: UpdateSensorTagInput!) {
    updateSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensorTag = /* GraphQL */ `
  mutation DeleteSensorTag($input: DeleteSensorTagInput!) {
    deleteSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        name
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSub = /* GraphQL */ `
  mutation CreateSub($input: CreateSubInput!) {
    createSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSub = /* GraphQL */ `
  mutation UpdateSub($input: UpdateSubInput!) {
    updateSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSub = /* GraphQL */ `
  mutation DeleteSub($input: DeleteSubInput!) {
    deleteSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sensors {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          sub {
            id
            topic
            deviceId
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSub = /* GraphQL */ `
  mutation CreateUserSub($input: CreateUserSubInput!) {
    createUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSub = /* GraphQL */ `
  mutation UpdateUserSub($input: UpdateUserSubInput!) {
    updateUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSub = /* GraphQL */ `
  mutation DeleteUserSub($input: DeleteUserSubInput!) {
    deleteUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          owners {
            nextToken
            __typename
          }
          stores {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        store {
          id
          name
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locations {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        admins {
          items {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        status
        sensors {
          items {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        fuelCells {
          items {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensorActive
        payment
        business
        degreePref
        resolution
        gateways {
          items {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSensorSub = /* GraphQL */ `
  mutation CreateSensorSub($input: CreateSensorSubInput!) {
    createSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSensorSub = /* GraphQL */ `
  mutation UpdateSensorSub($input: UpdateSensorSubInput!) {
    updateSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSensorSub = /* GraphQL */ `
  mutation DeleteSensorSub($input: DeleteSensorSubInput!) {
    deleteSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sub {
        id
        topic
        deviceId
        users {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        sensors {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      product {
        id
        name
        high
        low
        degreePref
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        min
        max
        createdAt
        updatedAt
        __typename
      }
      high
      low
      min
      max
      updatedAt
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession($input: UpdateSessionInput!) {
    updateSession(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      product {
        id
        name
        high
        low
        degreePref
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        min
        max
        createdAt
        updatedAt
        __typename
      }
      high
      low
      min
      max
      updatedAt
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession($input: DeleteSessionInput!) {
    deleteSession(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      readings {
        items {
          id
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          treatment {
            id
            tag
            active
            start
            end
            createdAt
            updatedAt
            __typename
          }
          createdAt
          error
          readingSensorId
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          minFull
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingTreatmentId
          session {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      product {
        id
        name
        high
        low
        degreePref
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          currentTempFull
          certified
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          fuelCell {
            name
            online
            lastUpdate
            fuel
            water
            tunnel
            createdAt
            updatedAt
            __typename
          }
          recording
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locationNote
          sensorLocationId
          device {
            id
            model
            manufacturer
            test
            createdAt
            updatedAt
            __typename
          }
          sensorDeviceId
          sensorUserId
          rssi
          updateInterval
          readings {
            nextToken
            __typename
          }
          tags {
            nextToken
            __typename
          }
          active
          alert
          alarm
          high
          low
          highC
          lowC
          email
          phone
          time
          raw
          duration
          monitor
          battery
          humidity
          leak
          subs {
            nextToken
            __typename
          }
          notes
          lastCheckIn
          alarms {
            nextToken
            __typename
          }
          notifications {
            nextToken
            __typename
          }
          customer {
            id
            first
            last
            email
            phone
            approved
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          events {
            nextToken
            __typename
          }
          logs {
            nextToken
            __typename
          }
          reports {
            nextToken
            __typename
          }
          reportingEnabled
          andrewTest
          validated
          timezone
          degreePref
          sessions {
            nextToken
            __typename
          }
          products {
            nextToken
            __typename
          }
          gateway {
            devEui
            connected
            lastCheckIn
            lastPacket
            firstConnect
            brand
            model
            sn
            gatewayOwnerId
            createdAt
            updatedAt
            __typename
          }
          sensorGatewayId
          createdAt
          updatedAt
          __typename
        }
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        min
        max
        createdAt
        updatedAt
        __typename
      }
      high
      low
      min
      max
      updatedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      name
      high
      low
      degreePref
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      min
      max
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      high
      low
      degreePref
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      min
      max
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      id
      name
      high
      low
      degreePref
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      sessions {
        items {
          id
          createdAt
          sensor {
            id
            uuid
            label
            name
            duel
            duelId
            serialNumber
            oem
            harnessNumber
            partNumber
            development
            downlink
            test
            version
            online
            currentTemp
            currentTemp2
            currentTempFull
            certified
            t1
            t2
            t3
            t4
            v1
            v2
            v3
            p1
            p2
            p3
            p4
            p5
            p6
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            compSuctionTemp
            compReturnTemp
            ambient
            displayValues
            code
            recording
            locationNote
            sensorLocationId
            sensorDeviceId
            sensorUserId
            rssi
            updateInterval
            active
            alert
            alarm
            high
            low
            highC
            lowC
            email
            phone
            time
            raw
            duration
            monitor
            battery
            humidity
            leak
            notes
            lastCheckIn
            reportingEnabled
            andrewTest
            validated
            timezone
            degreePref
            sensorGatewayId
            createdAt
            updatedAt
            __typename
          }
          readings {
            nextToken
            __typename
          }
          product {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          high
          low
          min
          max
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      min
      max
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(input: $input) {
      id
      createdAt
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        currentTempFull
        certified
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          treatments {
            nextToken
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          online
          lastUpdate
          fuel
          water
          user {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          tunnel
          createdAt
          updatedAt
          __typename
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        sensorDeviceId
        sensorUserId
        rssi
        updateInterval
        readings {
          items {
            id
            createdAt
            error
            readingSensorId
            temp1
            temp2
            temp3
            temp4
            voltage1
            voltage2
            voltage3
            pressure1
            pressure2
            pressure3
            pressure4
            pressure5
            pressure6
            min
            minFull
            min2
            am
            b1Syrup
            b2Syrup
            b3Syrup
            b4Syrup
            b1SyrupOut
            b2SyrupOut
            b3SyrupOut
            b4SyrupOut
            b1Water
            b2Water
            b3Water
            b4Water
            b1Defrost
            b2Defrost
            b3Defrost
            b4Defrost
            b1Liquid
            b2Liquid
            b3Liquid
            b4Liquid
            b1Fill
            b2Fill
            b3Fill
            b4Fill
            b1BIB
            b2BIB
            b3BIB
            b4BIB
            b1C02
            b2C02
            b3C02
            b4C02
            b1Temp
            b2Temp
            b3Temp
            b4Temp
            ambient
            H20Pressure
            C02Pressure
            leftHighPressureCoil
            rightHighPressureCoil
            compSuctionTemp
            compReturnTemp
            compCut
            leftCompContCoil
            rightCompContCoil
            testSignal
            testAcknowledge
            gnd1
            gnd2
            gnd3
            gnd4
            ACReturn
            ACIn
            readingTreatmentId
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        active
        alert
        alarm
        high
        low
        highC
        lowC
        email
        phone
        time
        raw
        duration
        monitor
        battery
        humidity
        leak
        subs {
          items {
            id
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notes
        lastCheckIn
        alarms {
          items {
            id
            input
            value
            valueC
            predicate
            duration
            active
            ack
            pause
            default
            start
            stop
            type
            timezone
            alarmSensorId
            activeDays
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        notifications {
          items {
            id
            type
            info
            active
            default
            pause
            nickname
            notificationSensorId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          sensors {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          distributor {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          store {
            id
            name
            createdAt
            updatedAt
            __typename
          }
          auth
          location {
            id
            name
            address
            city
            state
            zip
            code
            createdAt
            updatedAt
            __typename
          }
          locations {
            nextToken
            __typename
          }
          admin {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          admins {
            nextToken
            __typename
          }
          subs {
            nextToken
            __typename
          }
          type
          status
          sensors {
            nextToken
            __typename
          }
          fuelCells {
            nextToken
            __typename
          }
          sensorActive
          payment
          business
          degreePref
          resolution
          gateways {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        events {
          items {
            id
            createdAt
            resolved
            status
            result
            notify
            notes
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        logs {
          items {
            id
            createdAt
            alarm
            alerts
            logSensorId
            type
            trigger
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reports {
          items {
            id
            createdAt
            period
            reportSensorId
            periodStartTimestamp
            highTemps
            lowTemps
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        reportingEnabled
        andrewTest
        validated
        timezone
        degreePref
        sessions {
          items {
            id
            createdAt
            high
            low
            min
            max
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        products {
          items {
            id
            name
            high
            low
            degreePref
            type
            min
            max
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gateway {
          devEui
          connected
          lastCheckIn
          lastPacket
          firstConnect
          brand
          model
          sn
          owner {
            id
            first
            last
            email
            phone
            code
            approved
            auth
            type
            status
            sensorActive
            payment
            business
            degreePref
            resolution
            createdAt
            updatedAt
            __typename
          }
          sensors {
            nextToken
            __typename
          }
          gatewayOwnerId
          createdAt
          updatedAt
          __typename
        }
        sensorGatewayId
        createdAt
        updatedAt
        __typename
      }
      period
      reportSensorId
      periodStartTimestamp
      highTemps
      lowTemps
      updatedAt
      __typename
    }
  }
`;
